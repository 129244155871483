import {Link} from "react-router-dom";
import {BitcrushPoem} from "../urls/Urls";
import TextRing from "./TextRing";
import shell from "../static/13_shell.png";
import cloud from "../static/8_cloud.png";
import cocount from "../static/21_coconut.png";
import three from "../static/7_zen tree.png";
import {isMobileDeviceV2} from "../../utils/StyleUtils";

const Elit = () => {
    const isMob = isMobileDeviceV2()
    return (
        <div className={"projects"}>
            <div className={"grid-container"}>
                <div className={"grid-item"}>

                    <Link to={BitcrushPoem}>
                        <TextRing side={isMob ? 1.0 : 1.0} bottom={isMob ? 3 : 4} left={isMob ? 5 : 9.5} turn1={-0.2}
                                  turn2={0.1} revert={true}>bitcrush poem</TextRing>
                        <img className={"link-img position-0"} src={shell} alt={"moon"}/>
                    </Link>
                </div>
                <div className={"grid-item"}>

                    <Link to={"/ns"}>
                        <TextRing side={isMob ? 0.9 : 1.0} bottom={isMob ? 5 : 11} left={isMob ? 5 : 12.5} turn1={0.2}
                                  turn2={0.1} revert={false}>кроме ностальгии</TextRing>
                        <img className={"link-img position-1"} src={cloud} alt={"moon"}/>
                    </Link>
                </div>
                <div className={"grid-item"}>

                    <Link to={"/dd"}>
                        <TextRing side={isMob ? 0.9 : 1.0} bottom={isMob ? 2 : 11} left={isMob ? 3 : 3} turn1={0.2}
                                  turn2={0.2} revert={false}>цифровая смерть</TextRing>
                        <img className={"link-img position-1"} src={cocount} alt={"moon"}/>
                    </Link>
                </div>
                <div className={"grid-item"}>

                    <Link to={"/ul"}>
                        <TextRing side={isMob ? 0.9 : 1} bottom={isMob ? 3 : 12} left={isMob ? 7 : 7} turn1={-0.2}
                                  turn2={-0.01} revert={true}>&lt;/UL&gt;</TextRing>
                        <img className={"link-img position-1"} src={three} alt={"moon"}/>
                    </Link>
                </div>
3
            </div>
        </div>
    )
}

export default Elit