import {Texts} from "./Texts";

export const Details = ({lang}) => {
    return (
        <div className={"details"}>
            <div className={"texts"}>
                <h1>{Texts["details"][lang]}</h1>
                <p className={"font-core-style"}>{Texts["details-1"][lang]}</p>
                <p className={"font-core-style"}>{Texts["details-2"][lang]}</p>
                <p className={"font-core-style"}>{Texts["details-4"][lang]}</p>
            </div>
        </div>
    )
}